/*
 * Castalytics GmbH (c) 2022-2024
 * Project: snipocc
 */

import { type CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { type Roles } from '@snipocc/api';

export function hasAnyRole(...roles: Roles[]): CanActivateFn {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);

    const loggedIn = userService.isLoggedIn();

    console.debug('user is logged in:', loggedIn);
    if (!loggedIn) {
      return router.parseUrl('/login');
    }

    return userService.hasAnyRole(...roles);
  };
}

